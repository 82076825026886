import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Checks.css';

import ChecksStrings from './ChecksStrings';
import ChecksService from './ChecksService';

import ProductsService from '../products/ProductsService'

const Checks = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate();

    const [checks, setChecks] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    const [showMore, setShowMore] = useState(false);
    const [products, setProducts] = useState("");

    const getProducts = () => {
        ProductsService.get(1, 1000000).then(
            (response) => {
                setProducts(response);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }
    const getChecks = () => {
        ChecksService.get(1, currentPage * pageSize).then(
            (response) => {
                console.log("CHECKS", response);
                setShowMore(response.length == pageSize);
                const all = [];
                all.push(...response);
                setChecks(all);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        ChecksService.get(page, pageSize).then((response) => {
            const all = [];
            if (checks) {
                all.push(...checks);
            }
            if (response) {
                response.forEach(element => {
                    if (!all.find((x) => x.id == element.id)) {
                        all.push(element);
                    }
                });

            }

            setChecks(all);
            setCurrentPage(page);
            setShowMore(response.length == pageSize);

        });
    }

    const refresh = () => {
        getChecks();
        getProducts();
    }

    useEffect(() => {
        refresh();
    }, []);

    // "id": "b8da6d9f-b883-4c42-9c7d-2385bbcf9657",
    // "creation_date": "2017-07-21T17:32:28Z",
    // "client_ip": "10.110.51.1",
    // "license_key": "712b905d3c2dd97b284808b1dea48e1a00eeb32b2e457cf5550b4247e926420a",
    // "bot_username": "@SynWeb88197Bot",
    // "result": true

    const checksRender = !checks ? "" : checks.map((check) => {

        return <div key={check.id} className={"card card-check mb-4 " + (check.result ? "bg-success" : "bg-danger")}>
            <div className="card-body">
                <h5 className="card-title">{check.id}</h5>
                <p className="card-text">Ключ: {check.license_key}<br /><br />Бот: {check.bot_username}</p>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex">{check.result ? "Успешно" : "Нет"}</li>
                {check.creation_date && <li className="list-group-item">Дата: {format(parseISO(check.creation_date), "dd.MM.yyyy HH:mm")}</li>}
                <li className="list-group-item d-flex">{check.client_ip}</li>
                {/* <li className="list-group-item d-flex">
                    <button className="btn btn-secondary" onClick={(e) => navigate("/checks/" + check.id)}><i className="fa fa-pencil"></i></button>
                    <button onClick={(e) => deleteCheck(e, check.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                </li> */}
            </ul>
            {/* {checkId && (check.id == checkId) &&
                <Modal onClose={(e) => { navigate("/checks"); refresh(); }} show={true} title="Редактирование хэштега" id={"modal" + check.id}>
                    <CheckEditor data={check} onUpdated={refresh} />
                </Modal>} */}
        </div>
    });

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Проверки" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                {/* <button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                    Новый продукт
                </button> */}
                {/* <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search">Найти</button>
                </div> */}
            </div>

            <div>
                {checksRender}
            </div>

            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}

            {/* {showNewModal && <Modal show={true} title="Новый продукт" id="newModal" onClose={() => setShowNewModal(false)}>
                <CheckEditor onUpdated={refresh}/>
            </Modal>} */}
            <Toasts message={message} setMessage={setMessage} />
        </div>
    </div>)
}

export default Checks