import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Products.css';

import ProductsStrings from './ProductsStrings';
import ProductsService from './ProductsService';
import ProductEditor from './ProductEditor';

const Products = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showNewModal, setShowNewModal] = useState(false);

    const navigate = useNavigate();
    const { productId } = useParams();

    const [products, setProducts] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    const [showMore, setShowMore] = useState(false);

    const getProducts = () => {
        ProductsService.get(1, currentPage * pageSize).then(
            (response) => {
                console.log("PRODUCTS", response);
                setShowMore(response.length == pageSize);
                const all = [];
                all.push(...response);
                if (productId && !response.find(x => x.id == productId)) {
                    ProductsService.getOne(productId).then((productResponse) => {
                        all.push(productResponse);
                        setProducts(all);
                    });
                } else {
                    setProducts(all);
                }
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        ProductsService.get(page, pageSize).then((response) => {
            const all = [];
            if (products) {
                all.push(...products);
            }
            if (response) {
                response.forEach(element => {
                    if (!all.find((x) => x.id == element.id)) {
                        all.push(element);
                    }
                });

            }

            setProducts(all);
            setCurrentPage(page);
            setShowMore(response.length == pageSize);

        });
    }

    const deleteProduct = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить продукт?")) return;
        ProductsService.del(id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const refresh = () => {
        getProducts();
    }

    useEffect(() => {
        refresh();
    }, []);

    // id: Optional[int]
    // product_string: str
    // publication_reward: int
    // reply_reward: int
    // description: Optional[str]
    // creation_date: Optional[str]
    // enabled: bool

    const productsRender = !products ? "" : products.map((product) => {

        return <div key={product.id} className="card card-product mb-4">
            <div className="card-body">
                <h5 className="card-title">{product.id}</h5>
                <p className="card-text">{product.name}<br/><br/>{product.description}</p>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex">
                    <button className="btn btn-secondary" onClick={(e) => navigate("/products/" + product.id)}><i className="fa fa-pencil"></i></button>
                    <button onClick={(e) => deleteProduct(e, product.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                </li>
            </ul>
            {productId && (product.id == productId) &&
                <Modal onClose={(e) => { navigate("/products"); refresh(); }} show={true} title="Редактирование хэштега" id={"modal" + product.id}>
                    <ProductEditor data={product}  onUpdated={refresh}/>
                </Modal>}
        </div>
    });

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Продукты" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                <button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                    Новый продукт
                </button>
                {/* <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search">Найти</button>
                </div> */}
            </div>

            <div>
                {productsRender}
            </div>

            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}

            {showNewModal && <Modal show={true} title="Новый продукт" id="newModal" onClose={() => setShowNewModal(false)}>
                <ProductEditor onUpdated={refresh}/>
            </Modal>}
            <Toasts message={message} setMessage={setMessage} />
        </div>
    </div>)
}

export default Products