import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const get = (page = 1, pageSize = 1000) => {
    return axios.get(API_URL + 'check?page=' + page + '&page_size=' + pageSize, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const getOne = (id) => {
    return axios.get(API_URL + 'check/'+id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const create = (data) => {
    return axios.post(API_URL + 'check', data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const update = (data) => {
    return axios.post(API_URL + 'check/' + data.id, data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const del = (id) => {
    return axios.delete(API_URL + 'check/' + id,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const ProductsService = {
    get,
    getOne,
    update,
    create,
    del
}

export default ProductsService;