import MainTitle from "./common/MainTitle";

function Home() {
    return <div>
        <MainTitle title="Система лицензирования" />
        <section>
            <div className="container-fluid">
                <p className="pt-4 pb-4">Система лицензирования</p>
            </div>
        </section>
    </div>
}

export default Home;