import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Licenses.css';

import LicensesStrings from './LicensesStrings';
import LicensesService from './LicensesService';
import LicenseEditor from './LicenseEditor';

import ProductsService from '../products/ProductsService'

const Licenses = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showNewModal, setShowNewModal] = useState(false);

    const [justCopyLicenseKey, setJustCopyLicenseKey] = useState(null);

    const navigate = useNavigate();
    const { licenseId } = useParams();

    const [licenses, setLicenses] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    const [showMore, setShowMore] = useState(false);

    const [products, setProducts] = useState("");


    const getProducts = () => {
        ProductsService.get(1, 1000000).then(
            (response) => {
                setProducts(response);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const getLicenses = () => {
        LicensesService.get(1, currentPage * pageSize).then(
            (response) => {
                console.log("LICENSES", response);
                setShowMore(response.length == pageSize);
                const all = [];
                all.push(...response);
                if (licenseId && !response.find(x => x.id == licenseId)) {
                    LicensesService.getOne(licenseId).then((licenseResponse) => {
                        all.push(licenseResponse);
                        setLicenses(all);
                    });
                } else {
                    setLicenses(all);
                }
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        LicensesService.get(page, pageSize).then((response) => {
            const all = [];
            if (licenses) {
                all.push(...licenses);
            }
            if (response) {
                response.forEach(element => {
                    if (!all.find((x) => x.id == element.id)) {
                        all.push(element);
                    }
                });

            }

            setLicenses(all);
            setCurrentPage(page);
            setShowMore(response.length == pageSize);

        });
    }

    const deleteLicense = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить лицензию?")) return;
        LicensesService.del(id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const refresh = () => {
        getLicenses();
        getProducts();
    }

    useEffect(() => {
        refresh();
    }, []);


    // "id": "b8da6d9f-b883-4c42-9c7d-2385bbcf9657",
    // "product_ids": [],
    // "comment": "Тестовая лицензия для helpdesk.",
    // "creation_date": "2017-07-21T17:32:28Z",
    // "update_date": "2017-07-21T17:32:28Z",
    // "valid_until": "2017-07-21T17:32:28Z",
    // "license_key": "712b905d3c2dd97b284808b1dea48e1a00eeb32b2e457cf5550b4247e926420a",
    // "bot_username": "@SynWeb88197Bot"


    const licensesRender = !licenses ? "" : licenses.map((license) => {

        const licenseProducts = license.product_ids && license.product_ids.length > 0 && products.length > 0 ?
            license.product_ids.map((product) => {
                const pr = products.filter(x => x.id == product);
                if (pr && pr.length == 1) {
                    return pr[0].name;
                } else {
                    return product;
                }
            }).join(', ')
            : "Все продукты";


        return <div key={license.id} className="card card-license mb-4">
            <div className="card-body">
                <h5 className="card-title">
                    <button class="btn btn-outline-primary" onClick={() => 
                        {   navigator.clipboard.writeText(license.license_key); 
                            setJustCopyLicenseKey(license.license_key); 
                            setInterval(() => { setJustCopyLicenseKey(null); }, 3000);
                        }}>
                        {justCopyLicenseKey != license.license_key && <em class="fa fa-regular fa-copy"></em>}
                        {justCopyLicenseKey == license.license_key && <em class="fa fa-solid fa-check"></em>}
                    </button> Ключ лицензии: {license.license_key}
                </h5>
                <p className="card-text">Бот: {license.bot_username}<br />{license.comment}<br />Продукты в лицензии: {licenseProducts}</p>
            </div>
            <ul className="list-group list-group-flush">
                {license.creation_date && <li className="list-group-item">Дата создания: {format(parseISO(license.creation_date), "dd.MM.yyyy HH:mm")}</li>}
                {license.update_date && <li className="list-group-item">Дата обновления: {format(parseISO(license.update_date), "dd.MM.yyyy HH:mm")}</li>}
                {license.valid_until ? <li className="list-group-item">Действует до: {format(parseISO(license.valid_until), "dd.MM.yyyy HH:mm")}</li>
                    : <li className='list-group-item'>Бессрочная лицензия</li>}
                <li className="list-group-item d-flex">
                    <button className="btn btn-secondary" onClick={(e) => navigate("/licenses/" + license.id)}><i className="fa fa-pencil"></i></button>
                    <button onClick={(e) => deleteLicense(e, license.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                </li>
            </ul>
            {licenseId && (license.id == licenseId) &&
                <Modal onClose={(e) => { navigate("/licenses"); refresh(); }} show={true} title="Редактирование лицензии" id={"modal" + license.id}>
                    <LicenseEditor products={products} data={license} onUpdated={refresh} />
                </Modal>}
        </div>
    });

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Лицензии" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                <button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                    Новая лицензия
                </button>
                {/* <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search">Найти</button>
                </div> */}
            </div>

            <div>
                {licensesRender}
            </div>

            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}

            {showNewModal && <Modal show={true} title="Новая лицензия" id="newModal" onClose={() => setShowNewModal(false)}>
                <LicenseEditor products={products} onUpdated={refresh} />
            </Modal>}
            <Toasts message={message} setMessage={setMessage} />
        </div>
    </div>)
}

export default Licenses