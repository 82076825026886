
import Strings from "./Strings";

const errorToString = (error) => {
    const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
    return Strings.errors[resMessage] ? Strings.errors[resMessage] : resMessage
}

const defaults = {
    PAGE_SIZE: 10
}


const Helper = {
    errorToString,
    defaults
}

export default Helper