import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import { format, parseISO } from 'date-fns';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Licenses.css';

import LicensesStrings from './LicensesStrings';
import LicensesService from './LicensesService';


const LicenseEditor = ({ data, onUpdated, products }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        bot_username: yup.string().required(),
        comment: yup.string(),
        product_ids: yup.array().of(yup.string()),

    });

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            bot_username: "",
            comment: ""
        }
    });

    const watchProducts = watch("product_ids");

    const productOptions = !products ? "" : products.map((product) =>
        <option key={"pr_" + product.id} value={product.id}>{product.name}</option>
    );

    useEffect(() => {
        if (data) {
            if (data) {
                Object.entries(data).forEach((dataItem) => {
                    setValue(dataItem[0], dataItem[1]);
                });
            }
        }
    }, [data]);

    // id: Optional[int]
    // license_string: str
    // publication_reward: int
    // reply_reward: int
    // description: Optional[str]
    // creation_date: Optional[str]
    // enabled: bool

    const handleUpdate = (formData) => {
        setMessage("");
        setSuccessMessage("");
        const func = data ? LicensesService.update : LicensesService.create;
        func(formData).then((response) => {
            setSuccessMessage("Лицензия успешно " + (data ? "обновлена" : "создана"));
            setInterval(() => {
                setSuccessMessage("");
            }, 5000);
            onUpdated();
            if (!data) {
                navigate("/licenses/");
                reset();
            }
        },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    };



    return (<div>
        {data && <ul className="no-bullets">
            <li>id: {data.id}</li>
            <li>Ключ: {data.license_key}</li>
            <li>Дата создания: {format(parseISO(data.creation_date), "dd.MM.yyyy")}</li>
            {data.update_date && <li>Дата обновления: {format(parseISO(data.update_date), "dd.MM.yyyy")}</li>}
        </ul>}

        <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
            <div className="form-group">
                <label>Bot username</label>
                <input role="presentation"
                    name="bot_username"
                    type="text"
                    {...register('bot_username')}
                    className={`form-control ${errors.bot_username ? 'is-invalid' : ''}`}
                />
                <div className="form-error">{errors.bot_username?.message}</div>
            </div>
            <div className="form-group">
                <label>Комментарий</label>
                <textarea rows="3" role="presentation"
                    {...register('comment')}
                    className={`form-control ${errors.comment ? 'is-invalid' : ''}`}
                ></textarea>
                <div className="form-error">{errors.comment?.message}</div>
            </div>
            <div className="form-group">
                <label>Действует до</label>
                <Controller
                    control={control}
                    name="valid_until"
                    render={({ field }) => (
                        <DatePicker
                            className={`form-control ${errors.valid_until ? 'is-invalid' : ''}`}
                            dateFormat="dd.MM.yyyy"
                            //showTimeSelect
                            //locale="ru"
                            placeholderText="Выберите дату"
                            onChange={(date) => { field.onChange(format(date, "yyyy-MM-dd")) }}
                            selected={field.value && new Date(field.value)}
                        />
                    )}
                />
            </div>
            <div className="form-group">
                <label>Продукты</label>{watchProducts && watchProducts.length ? <button onClick={() => setValue("product_ids", [])} type='button' className='ms-3 mb-2 btn btn-sm btn-outline-secondary'>Все продукты</button> : ""}
                <select name="product_ids"
                    multiple={true}
                    {...register('product_ids')}
                    className="form-control"
                >
                    {productOptions}
                </select>
            </div>
            <div className="mt-2 d-flex align-items-center justify-content-between">
                <button type="submit" className="btn btn-primary">
                    {data ? "Обновить" : "Создать"}
                </button>
            </div>
        </form>
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}
        {successMessage && (<div className="alert alert-success mt-4">
            {successMessage}
        </div>)}
    </div>)
}

export default LicenseEditor